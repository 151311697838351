import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import styles from "../../../styles/viewer.module.css";
import ReferralsAPI from "../../../services/ReferralsAPI";
import { Context } from "../../../store/AppStore";
import PreventionLinkReferralDialog from "./PreventionLinkReferralDialog";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../theme";
import { Container } from "@material-ui/core";
import auth from "../../../utils/auth";
import { useStateRef } from "../../../utils/useStateRef";
import { patientDataStore } from "../../../store/PatientDataStore";
import ReferralDocumentSelectionDialogContainer from "./ReferralDocumentSelectionDialogContainer";
import FormRendererAPI from "../../../services/FormRendererAPI";
import { FhirReferralsAPI } from "../../../services";
import {
  formEventPayloadToFhirReferral,
  getUserOrgByName,
  parseBundleToServices,
} from "../FhirReferrals/utils";
import useOrganizations from "../FhirReferrals/useOrganizations";
import moment from "moment";
import useReferrals from "../FhirReferrals/useReferrals";
import FhirReferralsList from "../FhirReferrals/FhirReferralsList";
import {
  FhirReferralActionButton,
  FhirReferralActions,
} from "../FhirReferrals";

const FORM_UUID = process.env.REACT_APP_REFERRAL_FORM_UUID;
const defaultServiceCategory = "prevention-link-service-provider";

const PreventionLinkReferralsViewer = (props) => {
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  const { patientId } = useParams();
  const [referralDialogOpen, setReferralDialogOpen] = useState(false);
  const [referralDialogSize, setReferralDialogSize] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [error, setError] = useState("");
  const [submitError, setSubmitError] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [referralDefaults, setReferralDefaults] = useState({});
  const [isReferralDocumentDialogOpen, setIsReferralDocumentDialogOpen] =
    useState(false);
  const [services, setServices, servicesRef] = useStateRef([]);
  // const [services, setServices] = useStateRef([]);
  // const [selectedServicesIds, setSelectedServicesIds] = useState([]);
  const [selectedServicesIds, setSelectedServicesIds, selectedServicesIdsRef] =
    useStateRef([]);
  const [selectedServices, setSelectedServices, selectedServicesRef] =
    useStateRef([]);

  const { organizations: availableOrganizations, error: organizationsError } =
    useOrganizations({});

  // const showAllReferrals = props.showAllReferrals && auth.canSeeAllReferrals;
  const showAllReferrals = false;

  const {
    referrals,
    serviceCategories,
    patient,
    createReferral,
    error: referralsError,
  } = useReferrals({
    patientId,
    showAllReferrals,
  });

  const handleGetServices = async () => {
    try {
      const payload = { serviceCategory: defaultServiceCategory };
      const servicesResponse = await FhirReferralsAPI.getReferralServices(
        payload
      );

      if (servicesResponse) {
        setServices([...parseBundleToServices(servicesResponse)]);
      }
    } catch (error) {
      console.log("DEBUG handleGetServices error: ", error);
      setServices([]);
    }
  };

  useEffect(
    () =>
      setSelectedServices(
        services.filter((x) =>
          selectedServicesIds.includes(x.healthcareService.id)
        )
      ),
    [services, selectedServicesIds]
  );

  // const userOrganization = useMemo(() => {
  //   console.log("DEBUG userinfo: ", auth.user().userinfo);
  //   if (availableOrganizations && availableOrganizations.length > 0) {
  //     const userOrganization = getUserOrgByName(
  //       auth.user().userinfo.orgName,
  //       availableOrganizations
  //     );
  //     if (userOrganization) return userOrganization;
  //   }
  //   return null;
  // }, [availableOrganizations]);

  const loadReferralData = useCallback(async () => {
    console.log("DEBUG loadReferralData");
    try {
      // setReferrals(
      //   await ReferralsAPI.getReferrals({
      //     patientId: props.showAllReferrals ? undefined : patientId,
      //     direction: (await auth.orgIsCommunity()) ? "incoming" : undefined,
      //   })
      // );
      // setReferralDefaults(
      //   await ReferralsAPI.getPreventionLinkReferralDefaults()
      // );
      handleGetServices();
      setReferralDefaults({
        urgent: false,
        serviceCategory: defaultServiceCategory,
        receivingOrganizationName: "",
        description: "",
      });
    } catch (error) {
      console.log("DEBUG loadReferralData error: ", error);
      setError(error.message);
    }
  }, [patientId, props.showAllReferrals, showAllReferrals]);

  const showReferralDialog = useMemo(() => {
    return (
      !!availableOrganizations &&
      availableOrganizations.length > 0 &&
      !!referralDialogSize &&
      !!patientData &&
      !!referralDefaults
    );
  }, [
    availableOrganizations,
    referralDialogSize,
    patientData,
    referralDefaults,
  ]);

  useEffect(() => {
    console.log("DEBUG availableOrganizations: ", availableOrganizations);
  }, [availableOrganizations]);

  useEffect(() => {
    console.log("DEBUG referralDialogSize: ", referralDialogSize);
  }, [referralDialogSize]);

  useEffect(() => {
    console.log("DEBUG patientData: ", patientData);
  }, [patientData]);

  useEffect(() => {
    console.log("DEBUG referralDefaults: ", referralDefaults);
  }, [referralDefaults]);

  useEffect(() => {
    console.log("DEBUG showReferralDialog: ", showReferralDialog);
  }, [showReferralDialog]);

  const getReferralPayload = (userOrganization) => {
    console.log(
      "DEBUG selectedServicesRef.current: ",
      selectedServicesRef.current
    );
    const selectedService = selectedServicesRef.current[0];

    if (!selectedService.organization) {
      console.log("DEBUG missing organization");
      return;
    }

    const performer = selectedService.organization;
    console.log("DEBUG performer: ", performer);

    const payload = {
      resourceType: "ServiceRequest",
      subject: {
        reference: `Patient/${patientId}`,
      },
      requester: {
        reference: `Organization/${userOrganization.id}`,
        display: userOrganization.name,
      },
      performer: [
        {
          reference: `Organization/${performer.id}`,
          display: performer.name,
        },
      ],
      status: "active",
      intent: "plan",
      category: [
        {
          coding: [
            {
              code: "prevention-link-referral",
            },
          ],
        },
      ],
      // priority: referralData?.urgent ? "urgent" : "routine",
      // note: [
      //   {
      //     text: referralData?.description,
      //   },
      // ],
      occurrenceDateTime: moment().toISOString(),
    };

    return payload;
  };

  const saveFormResponse = async (
    formResponse,
    relatedPatientId,
    referralId
  ) => {
    const base64FormResponse = btoa(JSON.stringify(formResponse));

    const binaryPayload = {
      contentType: "application/json",
      data: base64FormResponse,
      resourceType: "Binary",
    };

    const createdBinary = await FhirReferralsAPI.createBinary(binaryPayload);

    console.log("DEBUG createdBinary: ", createdBinary);

    if (!createdBinary?.id) return null;

    const currentDate = new Date().toISOString();

    const patientReference = `Patient/${relatedPatientId}`;

    const documentReferencePayload = {
      content: [
        {
          attachment: {
            contentType: `${createdBinary?.contentType}`,
            creation: currentDate,
            title: `PreventionLinkAssessmentResponse_${currentDate}`,
            url: `${process.env.REACT_APP_FHIR_BASE_URL}/Binary/${createdBinary?.id}`,
          },
        },
      ],
      masterIdentifier: {
        use: "official",
        value: "value document",
      },
      resourceType: "DocumentReference",
      status: "current",
      subject: {
        reference: `Patient/${relatedPatientId}`,
      },
      context: {
        sourcePatientInfo: {
          reference: patientReference,
          type: "Patient",
        },
        related: [
          {
            reference: `ServiceRequest/${referralId}`,
            type: "ServiceRequest",
          },
        ],
      },
    };

    const createdDocumentReference =
      await FhirReferralsAPI.createDocumentReference(documentReferencePayload);

    console.log("DEBUG createdDocumentReference: ", createdDocumentReference);

    if (!createdDocumentReference?.id) return null;

    return createdDocumentReference;
  };

  const handleReferralSave = async (formEvent) => {
    const formResponse =
      formEvent?.type === "form-submitted" && formEvent?.payload
        ? formEvent.payload
        : null;

    if (!formResponse) {
      return;
    }

    let orgs = [];
    const responseOrganizations = await FhirReferralsAPI.getOrganizations();
    if (responseOrganizations?.organizations) {
      orgs = responseOrganizations.organizations;
    }

    const userOrganization = getUserOrgByName(
      auth.user().userinfo.orgName,
      orgs
    );

    if (!userOrganization) {
      setError(
        `Organization with name ${auth.user().userinfo.orgName} does not exist`
      );
      return;
    }

    if (!patientId) {
      console.log("DEBUG: patient not defined");
      return "";
    }

    console.log("DEBUG formResponse: ", formResponse);

    const linkedDocuments = formResponse["linked_documents"];
    if (linkedDocuments) {
      delete formResponse["linked_documents"];
    }

    try {
      setShowBackdrop(true);
      const newReferralPayload = getReferralPayload(userOrganization);
      const createdReferral =
        await FhirReferralsAPI.createPreventionLinkReferral(newReferralPayload);
      for (const retrievalParameters of linkedDocuments || []) {
        await ReferralsAPI.linkDocument(
          createdReferral?.id,
          retrievalParameters
        );
      }
      const savedResponse = await saveFormResponse(
        formResponse,
        patientId,
        createdReferral?.id
      );

      await loadReferralData();
      setShowBackdrop(false);
      setReferralDialogOpen(false);
      history.push(
        `/patient/search/${patientId}/info/1/referrals/${createdReferral?.id}`
      );
      return "";
    } catch (error) {
      setSubmitError(error);
      setShowBackdrop(false);
      return error.message;
    }
  };

  const messageListener = useRef(async (event) => {
    const formEvent = event.data;
    if (formEvent.type === "form-submitted") {
      await handleReferralSave(formEvent);
    }
    if (formEvent.type === "link-documents") {
      setIsReferralDocumentDialogOpen(true);
    }
  });

  useEffect(() => {
    loadReferralData();
  }, [loadReferralData]);

  useEffect(() => {
    const listener = messageListener.current;
    FormRendererAPI.getReferralFormSize(FORM_UUID).then((size) => {
      window.addEventListener("message", listener);
      setReferralDialogSize(size);
    });
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    patientDataStore
      .getPatientData(patientId)
      .then((patientData) => setPatientData(patientData));
  }, [patientId]);

  const handleReferralClick = (referral) => {
    if (referral) {
      dispatch({ type: "UPDATE_REFERRAL", payload: referral });
      if (showAllReferrals) {
        history.push(`/referrals/${referral.id}`);
      } else {
        history.push(
          `/patient/search/${patientId}/info/1/referrals/${referral.id}`
        );
      }
    }
  };

  const handleNewReferralClick = () => {
    setReferralDialogOpen(true);
  };

  const handleReferralDialogClosed = () => {
    setReferralDialogOpen(false);
  };

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>{error}</Container>
      </ThemeProvider>
    );
  }
  return (
    <Fragment>
      {showReferralDialog ? (
        <PreventionLinkReferralDialog
          open={referralDialogOpen}
          handleClose={handleReferralDialogClosed}
          formUUID={FORM_UUID}
          size={referralDialogSize}
          patientData={patientData}
          showBackdrop={showBackdrop}
          submitError={submitError}
          referralDefaults={referralDefaults}
          services={services}
          selectedProgramsIds={selectedServicesIds}
          setSelectedProgramsIds={setSelectedServicesIds}
          selectedPrograms={selectedServices}
        />
      ) : null}
      {isReferralDocumentDialogOpen && (
        <ReferralDocumentSelectionDialogContainer
          open={isReferralDocumentDialogOpen}
          handleSave={() => {}}
          handleClose={() => {
            setIsReferralDocumentDialogOpen(false);
          }}
          patientId={patientId}
        />
      )}
      <div className={styles.documentViewer}>
        {showReferralDialog ? (
          <FhirReferralActions>
            <FhirReferralActionButton
              text="Create Referral"
              action={handleNewReferralClick}
            />
          </FhirReferralActions>
        ) : null}
        <FhirReferralsList
          showAllReferrals={showAllReferrals}
          referrals={referrals}
          onReferralClick={handleReferralClick}
        />
      </div>
    </Fragment>
  );
};

export default withRouter(PreventionLinkReferralsViewer);
